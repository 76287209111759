const Footer = () => {
  return (
    <div className="footer">
      <div className="version">Version {process.env.REACT_APP_VERSION}</div>
      <div className="copyright">
        <a href={"https://symatec.net"} rel="noreferrer" target={"_blank"}>
          Symatec
        </a>{" "}
        2023-2024©
      </div>
    </div>
  );
};

export default Footer;
